import Enum from './enum'

/**
 * 学校の学期制
 */
class TermPattern extends Enum {
    static TWO = 2; // 2学期制
    static THREE = 3; // 3学期制

    static values() {
        return {
            [this.TWO]: '2学期制',
            [this.THREE]: '3学期制',
        }
    }
}

export default TermPattern;
