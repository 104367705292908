import Enum from './enum'

/**
 * 本文校区分
 */
class HeadOrBranch extends Enum {
    static HEAD = 1; // 本校
    static BRANCH = 9; // 分校

    static values() {
        return {
            [this.HEAD]: '本校',
            [this.BRANCH]: '分校',
        }
    }
}

export default HeadOrBranch;
