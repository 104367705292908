/**
 * 辞書ファイル
 *
 * その学年で習う漢字は使って良い
 * ひらがなばかりになるときは、文節でスペースを開けること
 *
 * 1年生: 一 右 雨 円 王 音 下 火 花 貝 学 気 九 休 玉 金 空 月 犬 見 五 口 校 左 三 山 子 四 糸 字 耳 七 車 手 十 出 女 小 上 森 人 水 正 生 青 夕 石 赤 千 川 先 早 草 足 村 大 男 竹 中 虫 町 天 田 土 二 日 入 年 白 八 百 文 木 本 名 目 立 力 林 六（80字）
 * 2年生: 引 羽 雲 園 遠 何 科 夏 家 歌 画 回 会 海 絵 外 角 楽 活 間 丸 岩 顔 汽 記 帰 弓 牛 魚 京 強 教 近 兄 形 計 元 言 原 戸 古 午 後 語 工 公 広 交 光 考 行 高 黄 合 谷 国 黒 今 才 細 作 算 止 市 矢 姉 思 紙 寺 自 時 室 社 弱 首 秋 週 春 書 少 場 色 食 心 新 親 図 数 西 声 星 晴 切 雪 船 線 前 組 走 多 太 体 台 地 池 知 茶 昼 長 鳥 朝 直 通 弟 店 点 電 刀 冬 当 東 答 頭 同 道 読 内 南 肉 馬 売 買 麦 半 番 父 風 分 聞 米 歩 母 方 北 毎 妹 万 明 鳴 毛 門 夜 野 友 用 曜 来 里 理 話（160字）
 * 3年生: 悪 安 暗 医 委 意 育 員 院 飲 運 泳 駅 央 横 屋 温 化 荷 界 開 階 寒 感 漢 館 岸 起 期 客 究 急 級 宮 球 去 橋 業 曲 局 銀 区 苦 具 君 係 軽 血 決 研 県 庫 湖 向 幸 港 号 根 祭 皿 仕 死 使 始 指 歯 詩 次 事 持 式 実 写 者 主 守 取 酒 受 州 拾 終 習 集 住 重 宿 所 暑 助 昭 消 商 章 勝 乗 植 申 身 神 真 深 進 世 整 昔 全 相 送 想 息 速 族 他 打 対 待 代 第 題 炭 短 談 着 注 柱 丁 帳 調 追 定 庭 笛 鉄 転 都 度 投 豆 島 湯 登 等 動 童 農 波 配 倍 箱 畑 発 反 坂 板 皮 悲 美 鼻 筆 氷 表 秒 病 品 負 部 服 福 物 平 返 勉 放 味 命 面 問 役 薬 由 油 有 遊 予 羊 洋 葉 陽 様 落 流 旅 両 緑 礼 列 練 路 和（200字）
 * 4年生: 愛 案 以 衣 位 囲 胃 印 英 栄 塩 億 加 果 貨 課 芽 改 械 害 街 各 覚 完 官 管 関 観 願 希 季 紀 喜 旗 器 機 議 求 泣 救 給 挙 漁 共 協 鏡 競 極 訓 軍 郡 径 型 景 芸 欠 結 建 健 験 固 功 好 候 航 康 告 差 菜 最 材 昨 札 刷 殺 察 参 産 散 残 士 氏 史 司 試 児 治 辞 失 借 種 周 祝 順 初 松 笑 唱 焼 象 照 賞 臣 信 成 省 清 静 席 積 折 節 説 浅 戦 選 然 争 倉 巣 束 側 続 卒 孫 帯 隊 達 単 置 仲 貯 兆 腸 低 底 停 的 典 伝 徒 努 灯 堂 働 特 得 毒 熱 念 敗 梅 博 飯 飛 費 必 票 標 不 夫 付 府 副 粉 兵 別 辺 変 便 包 法 望 牧 末 満 未 脈 民 無 約 勇 要 養 浴 利 陸 良 料 量 輪 類 令 冷 例 歴 連 老 労 録（200字）
 * 5年生: 圧 移 因 永 営 衛 易 益 液 演 応 往 桜 恩 可 仮 価 河 過 賀 快 解 格 確 額 刊 幹 慣 眼 基 寄 規 技 義 逆 久 旧 居 許 境 均 禁 句 群 経 潔 件 券 険 検 限 現 減 故 個 護 効 厚 耕 鉱 構 興 講 混 査 再 災 妻 採 際 在 財 罪 雑 酸 賛 支 志 枝 師 資 飼 示 似 識 質 舎 謝 授 修 述 術 準 序 招 承 証 条 状 常 情 織 職 制 性 政 勢 精 製 税 責 績 接 設 舌 絶 銭 祖 素 総 造 像 増 則 測 属 率 損 退 貸 態 団 断 築 張 提 程 適 敵 統 銅 導 徳 独 任 燃 能 破 犯 判 版 比 肥 非 備 俵 評 貧 布 婦 富 武 復 複 仏 編 弁 保 墓 報 豊 防 貿 暴 務 夢 迷 綿 輸 余 預 容 略 留 領 （185字）
 * 6年生: 異 遺 域 宇 映 延 沿 我 灰 拡 革 閣 割 株 干 巻 看 簡 危 机 揮 貴 疑 吸 供 胸 郷 勤 筋 系 敬 警 劇 激 穴 絹 権 憲 源 厳 己 呼 誤 后 孝 皇 紅 降 鋼 刻 穀 骨 困 砂 座 済 裁 策 冊 蚕 至 私 姿 視 詞 誌 磁 射 捨 尺 若 樹 収 宗 就 衆 従 縦 縮 熟 純 処 署 諸 除 将 傷 障 城 蒸 針 仁 垂 推 寸 盛 聖 誠 宣 専 泉 洗 染 善 奏 窓 創 装 層 操 蔵 臓 存 尊 宅 担 探 誕 段 暖 値 宙 忠 著 庁 頂 潮 賃 痛 展 討 党 糖 届 難 乳 認 納 脳 派 拝 背 肺 俳 班 晩 否 批 秘 腹 奮 並 陛 閉 片 補 暮 宝 訪 亡 忘 棒 枚 幕 密 盟 模 訳 郵 優 幼 欲 翌 乱 卵 覧 裏 律 臨 朗 論 （181字）
 *
 * 習わない漢字例: 撮 影 締 択
 */
export default [
    {
        phrase: 'ふり返り',
        grades: {
            1: 'ふりかえり',
            2: 'ふりかえり',
        }
    },
    {
        phrase: 'ふり返りを書く',
        grades: {
            1: 'ふりかえりをかく',
            2: 'ふりかえりを書く',
        }
    },
    {
        phrase: '書く',
        grades: {
            1: 'かく',
        }
    },
    {
        phrase: '書き直す',
        grades: {
            1: 'かきなおす',
        }
    },
    {
        //作 (2) 品(3) 写(3) 真(3)
        phrase: '作品の写真をとろう',
        grades: {
            1: 'さくひんの しゃしんを とろう',
            2: '作ひんの しゃしんを とろう',
        }
    },
    {
        //質(5) 問(3) 答(2)
        phrase: '質問に答えよう',
        grades: {
            1: 'しつもんに こたえよう',
            2: 'しつもんに答えよう',
            3: 'しつ問に答えよう',
            4: 'しつ問に答えよう',
        }
    },
    {
        //書(2) 終(3) 提(5) 出(1)
        phrase: '書き終わったら提出しよう',
        grades: {
            1: 'かきおわったら てい出しよう',
            2: '書きおわったら てい出しよう',
            3: '書き終わったら てい出しよう',
            4: '書き終わったら てい出しよう',
        }
    },
    {
        //提(5) 出(1)
        phrase: '提出',
        grades: {
            1: 'てい出',
            2: 'てい出',
            3: 'てい出',
            4: 'てい出',
        }
    },
    {
        //下(1) 書(2) 保(5) 存(6)
        phrase: '下書き保存',
        grades: {
            1: '下がきほぞん',
            2: '下書きほぞん',
            3: '下書きほぞん',
            4: '下書きほぞん',
            5: '下書き保ぞん',
        }
    },
    {
        //後(2) 書(2) 場(2) 合(2) 下(1) 書(2) 保(5) 存(6)
        phrase: '後で書く場合は、下書き保存しよう',
        grades: {
            1: 'あとでかく ばあいは、下がきほぞん しよう',
            2: '後で書く場合は、下書きほぞんしよう',
            3: '後で書く場合は、下書きほぞんしよう',
            4: '後で書く場合は、下書きほぞんしよう',
            5: '後で書く場合は、下書き保ぞんしよう',
        }
    },
    {
        //配(3) 日(1)
        phrase: '配られた日',
        grades: {
            1: 'くばられた日',
            2: 'くばられた日',
        }
    },
    {
        //配(3)
        phrase: '配られ',
        grades: {
            1: 'くばられ',
            2: 'くばられ',
        }
    },
    {
        //提(5) 出(1) 日(1)
        phrase: '提出した日',
        grades: {
            1: 'てい出した日',
            2: 'てい出した日',
            3: 'てい出した日',
            4: 'てい出した日',
        }
    },
    {
        //未(4) 提(5) 出(1)
        phrase: '未提出',
        grades: {
            1: 'みてい出',
            2: 'みてい出',
            3: 'みてい出',
            4: '未てい出',
        }
    },
    {
        //提(5) 出(1) 済(6)
        phrase: '提出済',
        grades: {
            1: 'てい出ずみ',
            2: 'てい出ずみ',
            3: 'てい出ずみ',
            4: 'てい出ずみ',
            5: '提出ずみ',
        }
    },
    {
        //友(2) 達(4)
        phrase: '友達',
        grades: {
            1: 'ともだち',
            2: '友だち',
        }
    },
    {
        //友(2) 達(4) 作(2) 品(3)
        phrase: 'みんなの作品',
        grades: {
            1: 'みんなの さくひん',
            2: 'みんなの作ひん',
            3: 'みんなの作品',
        }
    },
    {
        phrase: 'みんなの写真',
        grades: {
            1: 'みんなの しゃしん',
            2: 'みんなの しゃしん',
        }
    },
    {
        phrase: 'いま作品は見られません',
        grades: {
            1: 'いま さくひん は見られません',
            2: 'いま作ひんは見られません',
            3: 'いま作品は見られません',
        }
    },
    {
        phrase: 'いま写真は見られません',
        grades: {
            1: 'いま しゃしん は見られません',
            2: 'いま しゃしんは見られません',
            3: 'いま しゃしんは見られません',
        }
    },
    {
        //未(4) 提(5) 出(1)
        phrase: '未提出のワークがあります',
        grades: {
            1: 'みてい出のワークがあります',
            2: 'みてい出のワークがあります',
            3: 'みてい出のワークがあります',
            4: '未てい出のワークがあります',
        }
    },
    {
        //締(x) 切(2) 過(5)
        phrase: 'しめ切りを過ぎているワークがあります',
        grades: {
            1: 'しめきりを すぎている ワークがあります',
            2: 'しめ切りを すぎている ワークがあります',
            3: 'しめ切りを すぎている ワークがあります',
            4: 'しめ切りを すぎている ワークがあります',
        }
    },
    {
        //書(2)
        phrase: 'あなたが書いたコメント',
        grades: {
            1: 'あなたが かいた コメント',
        }
    },
    {
        //作(2) 品(3)
        phrase: '作品',
        grades: {
            1: 'さくひん',
            2: '作ひん',
        }
    },
    {
        phrase: '写真',
        grades: {
            1: 'しゃしん',
            2: 'しゃしん',
        }
    },
    {
        //作(2) 品(3)
        phrase: 'あなたの作品へのコメント',
        grades: {
            1: 'あなたの さくひんへの コメント',
            2: 'あなたの作ひんへのコメント',
        }
    },
    {
        phrase: 'あなたの写真へのコメント',
        grades: {
            1: 'あなたの しゃしんへの コメント',
            2: 'あなたの しゃしんへのコメント',
        }
    },
    {
        //作(2) 品(3)
        phrase: 'さんの作品へのコメント',
        grades: {
            1: 'さんの さくひんへの コメント',
            2: 'さんの作ひんへのコメント',
        }
    },
    {
        phrase: 'さんの写真へのコメント',
        grades: {
            1: 'さんの しゃしんへの コメント',
            2: 'さんの しゃしんへのコメント',
        }
    },
    {
        //作(2) 品(3)
        phrase: '作品へのコメント',
        grades: {
            1: 'さくひんへの コメント',
            2: '作ひんへのコメント',
        }
    },
    {
        phrase: '写真へのコメント',
        grades: {
            1: 'しゃしんへの コメント',
            2: 'しゃしんへの コメント',
        }
    },
    {
        //作(2) 品(3) 前(2)
        phrase: '作品の名前をつけよう',
        grades: {
            1: 'さくひんの 名まえをつけよう',
            2: '作ひんの名前をつけよう',
        }
    },
    {
        phrase: '名前をつけよう',
        grades: {
            1: '名まえをつけよう',
            2: '名前をつけよう',
        }
    },
    {
        phrase: '作品の写真をとろう',
        grades: {
            1: 'さくひんの しゃしんをとろう',
            2: '作ひんの しゃしんをとろう',
        }
    },
    {
        phrase: '写真をとろう',
        grades: {
            1: 'しゃしんをとろう',
            2: 'しゃしんをとろう',
        }
    },
    {
        //選(4) 択(x)
        phrase: '選たく',
        grades: {
            1: 'せんたく',
            2: 'せんたく',
            3: 'せんたく',
        }
    },
    {
        //回(2) 目(1)
        phrase: '回目のふり返り',
        grades: {
            1: 'かい目のふりかえり',
            2: '回目のふりかえり',
        }
    },
    {
        //名(1) 前(2) 入(1) 力(1)
        phrase: 'お名前を入力してください',
        grades: {
            1: 'お名まえを入力してください',
        }
    },
    {
        //名(1) 前(2)
        phrase: 'お名前',
        grades: {
            1: 'お名まえ',
        }
    },
    {
        //登(3) 録(4)
        phrase: '登録',
        grades: {
            1: 'とうろく',
            2: 'とうろく',
            3: '登ろく',
        }
    },

    {
        //使(3) 方(2) 紙面表記に合わせる
        phrase: '使い方',
        grades: {
            1: 'つかいかた',
            2: 'つかいかた',
        }
    },

    /* validation message */
    {
        phrase: '入力内容に誤りがあります',
        grades: {
            1: 'まだこたえられていないしつもんがあるよ',
            2: 'まだ答えられていないしつもんがあるよ',
            3: 'まだ答えられていないしつ問があるよ',
            4: 'まだ答えられていないしつ問があるよ',
            5: 'まだ答えられていない質問があります',
            6: 'まだ答えられていない質問があります',
        }
    },
];