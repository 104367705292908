import Enum from './enum'

/**
 * 学校種（文部科学省学校コード準拠）
 */
class SchoolType extends Enum {
    static ELEMENTARY = 'B1'; // 小学校
    static JUNIOR_HIGH = 'C1'; // 中学校
    static COMPULSORY = 'C2'; // 義務教育学校

    static values() {
        return {
            [this.ELEMENTARY]: '小学校',
            [this.JUNIOR_HIGH]: '中学校',
            [this.COMPULSORY]: '義務教育学校',
        }
    }
}

export default SchoolType;
