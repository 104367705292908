import { isNud } from "@/shared/utilities/typing";

/**
 * アニコマオプション エンティティ
 */
class Option {
    option_id;
    school_class_id;
    option_work_share;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.option_id = properties.option_id;
        this.school_class_id = properties.school_class_id;
        this.option_work_share = properties.option_work_share;
    }
}

export default Option;
