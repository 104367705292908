<template>
    <router-link :to="backTo" class="btn btn-outline-secondary nowrap" v-if="backTo.name">
        <i class="bi" :class="icon"></i>
        {{ text }}
    </router-link>
</template>

<script>
export default {
    name: 'ButtonBack',
    props: {
        text: {
            type: String,
            default: '戻る',
        },
        icon: {
            type: String,
            default: 'bi-arrow-return-left',
        },
        type: {
            type: String,
            default: 'button',
        },
        backTo: {
            type: Object,
            default: () => {
                return { name: null };
            }
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
