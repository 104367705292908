import { isNud } from '@/shared/utilities/typing'
import HeadOrBranch from '@/shared/models/enums/head-or-branch'
import Pref from '@/shared/models/enums/pref'
import SchoolType from '@/shared/models/enums/school-type'
import SchoolOwnership from '@/shared/models/enums/school-ownership'
import TermPattern from '@/shared/models/enums/term-pattern'

/**
 * 学校 エンティティ
 */
class School {
    school_id;
    school_code;
    school_name;
    school_type;
    school_ownership;
    head_or_branch;
    zip_code;
    pref;
    address;
    is_setup;
    term_pattern;
    option_require_ip;
    option_require_ip_whitelist;
    option_require_pin;
    repocraft_evaluation;
    has_previous_orders;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.school_id = properties.school_id;
        this.school_code = properties.school_code;
        this.school_name = properties.school_name;
        this.school_type = properties.school_type;
        this.school_ownership = properties.school_ownership;
        this.head_or_branch = properties.head_or_branch;
        this.zip_code = properties.zip_code;
        this.pref = properties.pref;
        this.address = properties.address;
        this.is_setup = properties.is_setup;
        this.term_pattern = properties.term_pattern;
        this.option_require_ip = properties.option_require_ip;
        this.option_require_ip_whitelist = properties.option_require_ip_whitelist;
        this.option_require_pin = properties.option_require_pin;
        this.repocraft_evaluation = properties.repocraft_evaluation;
        this.has_previous_orders = properties.has_previous_orders; //過去に注文があるか
    }

    get school_type_display() {
        return SchoolType.get(this.school_type);
    }

    get school_ownership_display() {
        return SchoolOwnership.get(this.school_ownership);
    }

    get head_or_branch_display() {
        return HeadOrBranch.get(this.head_or_branch);
    }

    get full_address() {
        return Pref.get(this.pref) + (this.address ?? '');
    }

    get term_pattern_display() {
        return TermPattern.get(this.term_pattern);
    }

    get terms() {
        return TermPattern.get(this.term_pattern);
    }
}

export default School;
