import Enum from './enum'

/**
 * 設置区分
 */
class SchoolOwnership extends Enum {
    static NATIONAL = 1; // 国立
    static INSTITUTION = 2; // 公立
    static ESTABLISHMENT = 3; // 私立

    static values() {
        return {
            [this.NATIONAL]: '国立',
            [this.INSTITUTION]: '公立',
            [this.ESTABLISHMENT]: '私立',
        }
    }
}

export default SchoolOwnership;
