<template>
    <h1 :class="optional_class">
        <div class="title d-flex">
            <i class="text-primary bi" :class="icon"></i>
            {{ title }}
            <span v-if="false === $helper.isNud(title_sub_1)" class="title-sub-1">{{ title_sub_1 }}</span>
            <span v-if="false === $helper.isNud(title_sub_2)" class="title-sub-2">{{ title_sub_2 }}</span>
        </div>
        <div :class="optional_rightbox_class">
            <!-- 追加ボタン -->
            <slot/>
            <div>
                <button-back :backTo="backTo" :text="button_text"></button-back>
            </div>
        </div>
    </h1>
</template>

<script>
import ButtonBack from '@/shared/components/design/buttons/ButtonBack.vue';
export default {
    components: {
        ButtonBack,
    },
    name: 'PageTitle',
    props: {
        //タイトル文
        title: {
            type: String,
            default: 'ページタイトル'
        },
        //タイトル文を分割して装飾する場合
        title_sub_1: {
            type: String,
        },
        //タイトル文を分割して装飾する場合
        title_sub_2: {
            type: String,
        },
        icon: {
            type: String,
            default: 'bi-gear-fill'
        },
        backTo: {
            type: Object,

        },
        button_text: {
            type: String,
        },
        optional_class: {
            type: String,
        },
        optional_rightbox_class: {
            type: String,
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    h1 {display: flex; justify-content: space-between; align-items: center; border-bottom: 8px solid #EEE; margin: -1rem -0.75rem 1rem; padding: 1rem; font-size: 1.8rem; background-color: #fff;}
    .title i {margin-right: .7rem;}
    .btn {margin-left: 1rem;}
</style>
