import { isNud } from '@/shared/utilities/typing'
import Grade from '../enums/grade';
import Option from "@/shared/models/entities/anikoma/option";

/**
 * 学校クラス エンティティ
 */
class SchoolClass {
    school_class_id;
    year;
    grade;
    class_name;
    is_singular;
    priority;
    anikoma_option;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.school_class_id = properties.school_class_id;
        this.year = properties.year;
        this.grade = parseInt(properties.grade, 10);
        this.class_name = properties.class_name;
        this.is_singular = properties.is_singular;
        this.priority = properties.priority;
        this.anikoma_option = new Option(properties.anikoma_option);
    }

    get class_name_display() {
        if (this.is_singular) {
            return '単学級';
        }

        if (this.grade === Grade.ESC || this.grade === Grade.JHC) {
            return this.class_name;
        }

        return this.class_name + '組';
    }

    get grade_display() {
        return Grade.getGradeValue(this.grade);
    }
}

export default SchoolClass;
