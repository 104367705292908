<template>
    <button :type="type" class="btn btn-outline-secondary" :class="size" :disabled="disabled">
        <i class="bi" :class="icon"></i>
        {{ text }}
    </button>
</template>

<script>
export default {
    name: 'ButtonExecView',
    props: {
        text: {
            type: String,
            default: '印刷',
        },
        icon: {
            type: String,
            default: 'bi-printer',
            // ダウンロードアイコン -> bi-download
            // 印刷アイコン -> bi-printer もしくは bi-printer-fill
        },
        type: {
            type: String,
            default: 'button',
        },
        size: {
            type: String,
            default: 'btn-lg'
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
