import Enum from './enum'
import SchoolType from './school-type'
import { isNud } from '@/shared/utilities/typing'

/**
 * 学年
 */
class Grade extends Enum {
    static ES1 = 1; // 小1
    static ES2 = 2; // 小2
    static ES3 = 3; // 小3
    static ES4 = 4; // 小4
    static ES5 = 5; // 小5
    static ES6 = 6; // 小6
    static JH1 = 11; // 中1
    static JH2 = 12; // 中2
    static JH3 = 13; // 中3
    static ESC = 21; // クラブ活動
    static JHC = 22; // 部活動

    static values() {
        return {
            [this.ES1]: '小1',
            [this.ES2]: '小2',
            [this.ES3]: '小3',
            [this.ES4]: '小4',
            [this.ES5]: '小5',
            [this.ES6]: '小6',
            [this.JH1]: '中1',
            [this.JH2]: '中2',
            [this.JH3]: '中3',
            [this.ESC]: 'クラブ活動',
            [this.JHC]: '部活動',
        }
    }

    //学校種を除いた全学年名
    static gradeValues() {
        return {
            [this.ES1]: '1年生',
            [this.ES2]: '2年生',
            [this.ES3]: '3年生',
            [this.ES4]: '4年生',
            [this.ES5]: '5年生',
            [this.ES6]: '6年生',
            [this.JH1]: '1年生',
            [this.JH2]: '2年生',
            [this.JH3]: '3年生',
            [this.ESC]: 'クラブ活動',
            [this.JHC]: '部活動',
        }
    }

    //学校種を除いた学年名を取得
    static getGradeValue(key) {
        if (key in this.gradeValues()) {
            return this.gradeValues()[key];
        }

        return null;
    }

    //校種で絞った選択肢
    static optionsWhereSchoolType(school_type) {
        let object = this.whereSchoolType(school_type);
        let array = [];
        let keys = Object.keys(object);
        keys.forEach((key) => {
            array.push({
                key: key,
                label: object[key],
            });
        });

        return array;
    }

    //校種で絞った値を返す
    static getWhereSchoolType(school_type, key) {
        if (isNud(school_type) || isNud(key)) {
            return {};
        }

        const values = this.whereSchoolType(school_type);

        if (key in values) {
            return values[key];
        }

        return null;
    }

    //校種を絞る
    static whereSchoolType(school_type) {
        if (isNud(school_type)) {
            return {};
        }

        //小学校
        if (school_type === SchoolType.ELEMENTARY) {
            return {
                [this.ES1]: '1年',
                [this.ES2]: '2年',
                [this.ES3]: '3年',
                [this.ES4]: '4年',
                [this.ES5]: '5年',
                [this.ES6]: '6年',
                [this.ESC]: 'クラブ活動',
            }
        }

        //中学校
        if (school_type === SchoolType.JUNIOR_HIGH) {
            return {
                [this.JH1]: '1年',
                [this.JH2]: '2年',
                [this.JH3]: '3年',
                [this.JHC]: '部活動',
            }
        }

        //義務教育学校
        if (school_type === SchoolType.COMPULSORY) {
            return {
                [this.ES1]: '小1',
                [this.ES2]: '小2',
                [this.ES3]: '小3',
                [this.ES4]: '小4',
                [this.ES5]: '小5',
                [this.ES6]: '小6',
                [this.JH1]: '中1',
                [this.JH2]: '中2',
                [this.JH3]: '中3',
                [this.ESC]: 'クラブ活動',
                [this.JHC]: '部活動',
            }
        }

        return {};
    }
}

export default Grade;
