<template>
    <div class="screen-loader-wrap">
        <div class="screen-loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ScreenLoader',
}
</script>

<style scoped>
@-webkit-keyframes screen-loader {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    50% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        opacity: 0.7;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes screen-loader {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    50% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        opacity: 0.7;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

.screen-loader-wrap {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
}
.screen-loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 57px;
    height: 57px;
    margin: auto;
}

.screen-loader>div:nth-child(1) {
    -webkit-animation-delay: 0.22s;
    animation-delay: 0.22s;
    -webkit-animation-duration: 0.9s;
    animation-duration: 0.9s;
}

.screen-loader>div:nth-child(2) {
    -webkit-animation-delay: 0.64s;
    animation-delay: 0.64s;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
}

.screen-loader>div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
    animation-delay: -0.15s;
    -webkit-animation-duration: 0.63s;
    animation-duration: 0.63s;
}

.screen-loader>div:nth-child(4) {
    -webkit-animation-delay: -0.03s;
    animation-delay: -0.03s;
    -webkit-animation-duration: 1.24s;
    animation-duration: 1.24s;
}

.screen-loader>div:nth-child(5) {
    -webkit-animation-delay: 0.08s;
    animation-delay: 0.08s;
    -webkit-animation-duration: 1.37s;
    animation-duration: 1.37s;
}

.screen-loader>div:nth-child(6) {
    -webkit-animation-delay: 0.43s;
    animation-delay: 0.43s;
    -webkit-animation-duration: 1.55s;
    animation-duration: 1.55s;
}

.screen-loader>div:nth-child(7) {
    -webkit-animation-delay: 0.05s;
    animation-delay: 0.05s;
    -webkit-animation-duration: 0.7s;
    animation-duration: 0.7s;
}

.screen-loader>div:nth-child(8) {
    -webkit-animation-delay: 0.05s;
    animation-delay: 0.05s;
    -webkit-animation-duration: 0.97s;
    animation-duration: 0.97s;
}

.screen-loader>div:nth-child(9) {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
    -webkit-animation-duration: 0.63s;
    animation-duration: 0.63s;
}

.screen-loader>div {
    background-color: #333;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    margin: 2px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    display: inline-block;
    float: left;
    -webkit-animation-name: screen-loader;
    animation-name: screen-loader;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-delay: 0;
    animation-delay: 0;
}
</style>
